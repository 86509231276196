import React from "react"

import Layout from "components/Layout"
import Seo from "components/Seo"
import Breadcrumbs from "components/Breadcrumbs"

import { ContentSummary } from "page_components/checkout_summary"

const Summary = ({ location }) => {
  return (
    <Layout location={location}>
      <Breadcrumbs title="Podsumowanie" noIndex />
      <ContentSummary />
    </Layout>
  )
}

export const Head = () => <Seo title="Podsumowanie" />

export default Summary
